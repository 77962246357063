import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "./api/axios";
import useAuth from "./hooks/useAuth";
import Backdrop from "@mui/material/Backdrop";
import { Timestamp } from "firebase/firestore";
import CircularProgress from "@mui/material/CircularProgress";
const PARCELS_URL = "/parcels";


function ListParcels() {
  const [parcels, setParcels] = useState();
  const [isError, setIsError] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const navigate = useNavigate();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: auth?.userToken,
    },
  };

  const editParcel = (val) => {
    navigate("editParcel", { state: val });
  }

  const getDate = (date) => {
    let timestamp = new Timestamp(date?._seconds, date?._nanoseconds);
    let fdate = timestamp.toDate().toDateString();

    return <span>{fdate}</span>;
  }

  const deleteParcel = async (id) => {
    setLoading(true);
    try {
      const delConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: auth?.userToken,
        },
        // params: {
        //   id: id,
        // },
      };

      const route = PARCELS_URL + `/${id}`

      const response = await axios.delete(route, delConfig);

      if (response.status === 200) {
        setLoading(false);
        list();

        setIsAlertVisible(true);

        setTimeout(() => {
          setIsAlertVisible(false);
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      setError("Could not delete parcel. An error occured.");

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  };

  async function list() {
    try {
      const response = await axios.get(PARCELS_URL, config);

      if (response.status === 200) {
        setParcels(response.data);
        console.log(response.data);
      }
    } catch (error) {
      setError("Could not get parcel data. An error occured.");
      setLoading(false);

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }

  async function listParcelsByInstitutions() {
    const byInstConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: auth?.userToken,
      },
      params: {
        institution_id: auth?.admin.institutionId
      }
    }

    const route = PARCELS_URL + `/institution/`
    console.log(route)

    try {
      console.log(byInstConfig);
      const response = await axios.get(route, byInstConfig);

      console.log(response);
      if (response.status === 200) {
        setParcels(response.data);
      }
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
      console.log(error.response.data.message)

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }

  useEffect(() => {
    if (auth?.admin.adminType === "institution") {
      listParcelsByInstitutions()
    } else {
      list();
    }
  }, []);

  return (
    <div className="list-container">
      <h2>Parcels</h2>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {isError && (
        <div className="error-container">
          <div className="error-inner">{error}</div>
        </div>
      )}
      {isAlertVisible && (
        <div className="alert-container">
          <div className="alert-inner">Institution deleted!</div>
        </div>
      )}
      <table>
        <thead>
          <tr className="table-heading">
            <th>Receipient Name</th>
            <th>Receipient Number</th>
            <th>Receipient Location</th>
            <th>Institution</th>
            <th>Item</th>
            <th>Created on</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {parcels ? (
            parcels.map((val, key) => {
              return (
                <tr key={key} className="listed-item">
                  <td>
                    <span>{val.receipientInfo.fullname}</span>
                  </td>
                  <td>
                    <span>{val.receipientInfo.phoneNumber}</span>
                  </td>
                  <td>
                    <span>{val.toLocation.name}</span>
                  </td>
                  <td>
                    {val.institutionName}
                  </td>
                  <td>{val.itemName}</td>
                  <td>{getDate(val.createdDate)}</td>
                  <td>{val.status}</td>
                  <td>
                    <button className="list-button blue-button" onClick={() => editParcel(val)}>Edit</button>
                    <button
                      className="list-button red-button"
                      onClick={() => deleteParcel(val.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            // <Backdrop
            //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            //   open={true}
            // >
            //   <CircularProgress color="inherit" />
            // </Backdrop>
            <CircularProgress className="middle" color="inherit" />
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ListParcels;
