import React, { useState, useEffect } from "react";
import axios from "./api/axios";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import useAuth from "./hooks/useAuth";

const RIDER_URL = "/riders";

function EditDeliveryMan() {
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [licenseNo, setLicenseNo] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const photoRef = useRef(null);
  const { auth } = useAuth();

  useEffect(() => {
    setFullName(location?.state.fullname);
    setEmail(location?.state.email);
    setLicenseNo(location?.state.licenseNo);
    setPhoneNumber(location?.state.phoneNumber);
    setAddress(location?.state.address);
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();

    if (
      fullname === location.state.fullname &&
      email === location.state.email &&
      licenseNo === location.state.licenseNo &&
      phoneNumber === location.state.phoneNumber &&
      address === location.state.address
    ) {
      navigate("/listDM");
    } else {
      setLoading(true);
      setIsFilled(false);
      var updateData = {};

      if (fullname !== location.state.fullname) {
        updateData = { fullname: fullname };
      }
      if (email !== location.state.email) {
        updateData = { ...updateData, email: email };
      }
      if (licenseNo !== location.state.licenseNo) {
        updateData = { ...updateData, licenseNo: licenseNo };
      }
      if (phoneNumber !== location.state.phoneNumber) {
        updateData = { ...updateData, phoneNumber: phoneNumber };
      }
      if (address !== location.state.address) {
        updateData = { ...updateData, address: address };
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: auth?.userToken,
        },
        // params: {
        //   id: location.state.uid,
        // },
      };

      var postData = JSON.stringify(updateData);
      console.log(postData);
      const route = RIDER_URL + `/${location?.state.uid}`

      try {
        const response = await axios.put(
          route,
          postData,
          config
        );

        if (response.status === 200) {
          setLoading(false);
          setFullName("");
          setAddress("");
          setPhoneNumber("");
          setEmail("");
          setLicenseNo("");

          setIsAlertVisible(true);

          setTimeout(() => {
            setIsAlertVisible(false);
            navigate("/listDM");
          }, 3000);
        }
      } catch (error) {
        setLoading(false);
        setIsError(true);
        setError("Rider could not be edited. An error occured.");

        setTimeout(() => {
          setIsError(false);
        }, 5000);
      }
    }
  };

  return (
    <div className="add-dash">
      <form className="add-dash-details">
      <h2>Edit Delivery Man</h2>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="input-div">
          <label htmlFor="fullname">Full Name </label>
          <input
            value={fullname}
            type="text"
            id="fullname"
            onChange={(e) => {
              setFullName(e.target.value);
            }}
          ></input>
        </div>

        <div className="input-div">
          <label htmlFor="email">Email </label>
          <input
            value={email}
            type="text"
            id="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></input>
        </div>

        <div className="input-div">
          <label htmlFor="pass_img">License Plate Number </label>
          <input
            value={licenseNo}
            type="text"
            id="licenseNumber"
            onChange={(e) => {
              setLicenseNo(e.target.value);
            }}
          ></input>
        </div>

        <div className="input-div">
          <label htmlFor="pass_img">Mobile Number </label>
          <input
            value={phoneNumber}
            type="text"
            id="mobile"
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          ></input>
        </div>

        <div className="input-div">
          <label htmlFor="pass_img">Address </label>
          <input
            value={address}
            type="text"
            id="address"
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          ></input>
        </div>

        {isAlertVisible && (
          <div className="alert-container">
            <div className="alert-inner">Delivery Man updated!</div>
          </div>
        )}
        {isFilled && (
          <div className="error-container">
            <div className="error-inner">Fill all fields!</div>
          </div>
        )}
        {isImage && (
          <div className="error-container">
            <div className="error-inner">Error Uploading Image!</div>
          </div>
        )}
        {isError && (
          <div className="error-container">
            <div className="error-inner">{error}</div>
          </div>
        )}

        <div className="input-div">
          <button
            className="btn"
            onClick={async (e) => {
              await submitForm(e);
            }}
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditDeliveryMan;
