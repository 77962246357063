import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import axios from "./api/axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const DELIVERY_URL = "/deliveries";

function AddDeliveryDetails() {
  const [parcel, setParcel] = useState();
  const [amount, setAmount] = useState();
  const [isFilled, setIsFilled] = useState(false);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setParcel(location?.state);
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();

    if (amount === "") {
      setIsFilled(true);

      setTimeout(() => {
        setIsFilled(false);
      }, 5000);
    } else {
      setLoading(true);
      setIsFilled(false);

      var data = {
        parcel: parcel,
        amount: amount,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: auth?.userToken,
        },
      };

      var postData = JSON.stringify(data);
      console.log(postData);

      try {
        const response = await axios
          .post(DELIVERY_URL, postData, config)
          .then((data) => {
            setLoading(false);
            setAmount("");

            navigate("/createDelivery/assignDelivery", { state: data.data });
          });
      } catch (error) {
        setError("Could not add delivery details. An error occured.");

        setIsError(true);

        setTimeout(() => {
          setIsError(false);
        }, 5000);
      }
    }
  };

  return (
    <div className="add-dash">
      <form className="add-dash-details">
        <h2>Add Delivery Details</h2>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {isFilled && (
          <div className="error-container">
            <div className="error-inner">Fill all fields!</div>
          </div>
        )}
        {isError && (
          <div className="error-container">
            <div className="error-inner">{error}</div>
          </div>
        )}
        <div className="input-div">
          <label htmlFor="parcelId">Parcel ID: </label>
          <p>{parcel?.id}</p>
        </div>
        <div className="input-div">
          <label htmlFor="parcelId">Location: </label>
          <p>{parcel?.toLocation.name}</p>
        </div>
        <div className="input-div">
          <label htmlFor="receipientName">Receipient Name: </label>
          <p>{parcel?.receipientInfo.fullname}</p>
        </div>
        <div className="input-div">
          <label htmlFor="receipientNumber">Receipient Number: </label>
          <p>{parcel?.receipientInfo.phoneNumber}</p>
        </div>
        <div className="input-div">
          <label htmlFor="amount">Enter Amount: </label>
          <input
            type="text"
            id="amount"
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <button
            className="btn"
            onClick={async (e) => {
              await submitForm(e);
            }}
          >
            Assign Delivery
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddDeliveryDetails;
