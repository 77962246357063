import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import axios from "./api/axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const INSTITUTION_URL = "/institutions";

function EditInstitution() {
  const [name, setName] = useState("");
  const [region, setRegion] = useState("");
  const [branch, setBranch] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isFilled, setIsFilled] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location?.state) {
      navigate("/listInstitution");
    } else {
      setName(location?.state.name);
      setRegion(location?.state.region);
      setBranch(location?.state.branch);
      setPhoneNumber(location?.state.phoneNumber);
    }
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();

    if (
      name === location.state.name &&
      region === location.state.region &&
      branch === location.state.branch &&
      phoneNumber === location.state.phoneNumber
    ) {
      navigate("/listInstitution");
    } else {
      setLoading(true);
      setIsFilled(false);

      var updateData = {}

      if (name !== location.state.name) {
        updateData = { name: name };
      }
      if (region !== location.state.region) {
        updateData = { ...updateData, region: region };
      }
      if (branch !== location.state.branch) {
        updateData = { ...updateData, branch: branch };
      }
      if (phoneNumber !== location.state.phoneNumber) {
        updateData = { ...updateData, phoneNumber: phoneNumber };
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: auth?.userToken,
        },
        // params: {
        //   uid: location.state.uid,
        // }
      };

      var postData = JSON.stringify(updateData);
      console.log(postData);
      const route = INSTITUTION_URL + `/${location.state.uid}`

      try {
        const response = await axios.put(
          route,
          postData,
          config
        );

        console.log(response.status);

        if (response.status === 200) {
          setLoading(false);
          setName("");
          setRegion("");
          setBranch("");
          setPhoneNumber("");

          setIsAlertVisible(true);

          setTimeout(() => {
            setIsAlertVisible(false);
            navigate("/listInstitution");
          }, 3000);
        }
      } catch (error) {
        setLoading(false);
        setIsError(true);
        setError("Institution could not be edited. An error occured.");

        setTimeout(() => {
          setIsError(false);
        }, 5000);
      }
    }
  };


  return (
    <div className="add-dash">
      <form className="add-dash-details">
        <h2>Edit Institution</h2>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {isFilled && (
          <div className="error-container">
            <div className="error-inner">Fill all fields!</div>
          </div>
        )}
        {isAlertVisible && (
          <div className="alert-container">
            <div className="alert-inner">Institution edited!</div>
          </div>
        )}
        {isError && (
          <div className="error-container">
            <div className="error-inner">{error}</div>
          </div>
        )}
        <div className="input-div">
          <label htmlFor="fullname">Name of Institution</label>
          <input
            value={name}
            type="text"
            id="fullname"
            onChange={(e) => {
              setName(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label htmlFor="fullname">Region</label>
          <input
            value={region}
            type="text"
            id="email"
            onChange={(e) => {
              setRegion(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label htmlFor="fullname">Branch</label>
          <input
            value={branch}
            type="text"
            id="email"
            onChange={(e) => {
              setBranch(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label htmlFor="fullname">Phone Number</label>
          <input
            value={phoneNumber}
            type="text"
            id="email"
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <button
            className="btn"
            onClick={async (e) => {
              await submitForm(e);
            }}
          >
            Update Institution
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditInstitution;
