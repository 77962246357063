import React, { useState } from "react";
import useAuth from "./hooks/useAuth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";
import { collection, doc, getDoc, getFirestore } from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function AdminSignIn() {
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const [loading, setLoading] = useState(false);
  const firestore = getFirestore();

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredentials.user;
      const userDoc = await getDoc(
        doc(collection(firestore, "admins"), user.uid)
      );
      const admin = userDoc.data();
      const userToken = await user.getIdToken();
      setAuth({ userToken, admin });
      setLoading(false);
      navigate(from, { replace: true });
    } catch (error) {
      setLoading(false);
      setErrMsg("Could not sign in admin. Please try again.");
      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 3000);
    }
  };

  return (
    <div className="admin-signIn">
      <div className="sign-in-logo">
        <img src={require("../images/tups logo.jpg")}></img>
      </div>
      <h2>Admin Sign In</h2>
      <form className="sign-in-form">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="input-div">
          <label htmlFor="email">Email:</label>
          <input
            type="text"
            id="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          ></input>
        </div>
        {isError && (
          <div className="error-container">
            <div className="error-inner">Login Failed - {errMsg}</div>
          </div>
        )}
        <div className="input-div">
          <button className="btn" onClick={submitForm}>Sign In</button>
        </div>
      </form>
    </div>
  );
}

export default AdminSignIn;
