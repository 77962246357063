import React, { useState, useEffect } from "react";
import axios from "./api/axios";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import useAuth from "./hooks/useAuth";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

const PARCEL_URL = "/parcels";
const LIST_INSTITUTION_URL = "/institutions";

function EditParcel() {
  const [receipientName, setReceipientName] = useState("");
  const [receipientNumber, setReceipientNumber] = useState("");
  const [receipientLocation, setReceipientLocation] = useState("");
  const [institutionId, setInstitutionId] = useState("");
  const [institutions, setInstitutions] = useState();
  // const [items, setItems] = useState([{ item: "" }]);
  const [items, setItems] = useState("");
  const [isFilled, setIsFilled] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const center = useMemo(() => ({ lat: 5.6221, lng: -0.1734 }), []);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyD3ZRrdegh5bAKbOmCr8TIK4-cDn5eaKxU",
    libraries: ["places"],
  });

  useEffect(() => {
    setReceipientName(location?.state?.receipientInfo?.fullname);
    setReceipientNumber(location?.state?.receipientInfo?.phoneNumber);
    setReceipientLocation(location?.state?.toLocation?.name);
    var lat = location?.state?.toLocation?.latitude;
    var lng = location?.state?.toLocation?.longitude;
    setSelected({ lat, lng });
    setInstitutionId(location?.state?.institutionId);

    // var itemsList = [];

    // for (let i = 0; i < location?.state.items.length; i++) {
    //   itemsList = [...itemsList, { item: location?.state.items[i] }];
    // }
    setItems(location?.state.itemName);
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();

    // var list = [];

    // for (let i = 0; i < items.length; i++) {
    //   list = [...list, items[i].item];
    // }

    if (
      receipientName === location?.state?.receipientInfo.fullname &&
      receipientNumber === location?.state?.receipientInfo.phoneNumber &&
      receipientLocation === location?.state.toLocation.name &&
      selected.lat === location?.state.toLocation.latitude &&
      selected.lng === location?.state.toLocation.longitude &&
      institutionId === location?.state.institutionId &&
      items === location?.state.itemName
    ) {
      navigate("/listParcels");
    } else {
      setLoading(true);
      setIsFilled(false);
      var updateData = {};

      if (
        receipientName !== location.state.receipientInfo.fullname ||
        receipientNumber !== location?.state.receipientInfo.phoneNumber
      ) {
        updateData = {
          receipientInfo: {
            fullname: receipientName,
            phoneNumber: receipientNumber,
          },
        };
      }

      if (
        selected.lat !== location?.state.toLocation.latitude ||
        selected.lng !== location?.state.toLocation.longitude ||
        receipientLocation !== location?.state.toLocation.name
      ) {
        updateData = {
          ...updateData,
          toLocation: {
            name: receipientLocation,
            latitude: selected.lat,
            longitude: selected.lng,
          },
        };
      }

      if (institutionId !== location?.state.institutionId) {
        updateData = { ...updateData, institutionId: institutionId };
      }

      if (items !== location?.state.itemName) {
        updateData = {
          ...updateData,
          itemName: items,
        };
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: auth?.userToken,
        },
        params: {
          id: location.state.id,
        },
      };

      var postData = JSON.stringify(updateData);
      console.log(postData);
      const route = PARCEL_URL + `/${location.state.id}`

      try {
        const response = await axios.put(route, postData, config);

        if (response.status === 200) {
          setLoading(false);
          setReceipientName("");
          setReceipientLocation("");
          setReceipientNumber("");
          // setItems([{ item: "" }]);
          setItems("");
          setInstitutionId("");

          setIsAlertVisible(true);

          setTimeout(() => {
            setIsAlertVisible(false);
            navigate("/listParcels");
          }, 3000);
        }
      } catch (error) {
        setLoading(false);
        setIsError(true);
        setError("Could not load parcel data. An error occured.");

        setTimeout(() => {
          setIsError(false);
        }, 5000);
      }
    }
  };

  // const handleAddItem = (e) => {
  //   e.preventDefault();

  //   setItems([...items, { item: "" }]);
  // };

  // const handleRemoveItem = (e, index) => {
  //   e.preventDefault();

  //   const list = [...items];
  //   list.splice(index, 1);
  //   setItems(list);
  // };

  // const handleItemChange = (e, index) => {
  //   e.preventDefault();

  //   const { name, value } = e.target;
  //   const list = [...items];
  //   list[index][name] = value;
  //   setItems(list);
  // };

  const PlacesAutocomplete = () => {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (address) => {
      setValue(address, false);
      setReceipientLocation(address);
      clearSuggestions();

      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setSelected({ lat, lng });
      console.log(address);
    };

    useEffect(() => {
      if (auth?.admin?.adminType === "institution") {
        setInstitutionId(auth?.admin?.institutionId);
      }
    }, []);

    return (
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className="combobox-input"
          placeholder="Search an address"
        />
        <ComboboxPopover className="combobox-popover">
          <ComboboxList>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    );
  };

  const listInstituitons = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: auth?.userToken,
      },
    };

    try {
      const response = await axios.get(LIST_INSTITUTION_URL, config);
      setInstitutions(response.data);
    } catch (error) {
      setError("Could not get institution data. An error occured.");

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  };

  useEffect(() => {
    listInstituitons();
  }, []);

  return (
    <div className="add-dash">
      <form className="add-dash-details">
      <h2>Edit Parcel</h2>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {isFilled && (
          <div className="error-container">
            <div className="error-inner">Fill all fields!</div>
          </div>
        )}
        {isAlertVisible && (
          <div className="alert-container">
            <div className="alert-inner">Parcel created!</div>
          </div>
        )}
        {isError && (
          <div className="error-container">
            <div className="error-inner">{error}</div>
          </div>
        )}
        <div className="input-div">
          <label htmlFor="receipientName">Receipient Name</label>
          <input
            value={receipientName}
            type="text"
            id="receipientName"
            onChange={(e) => {
              setReceipientName(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label htmlFor="receipientNumber">Receipient Number</label>
          <input
            value={receipientNumber}
            type="text"
            id="receipientNumber"
            onChange={(e) => {
              setReceipientNumber(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label htmlFor="items">Items</label>
          <input
            value={items}
            type="text"
            id="items"
            onChange={(e) => {
              setItems(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label htmlFor="receipientLocation">Receipient Location</label>
          <div className="places-container">
            <PlacesAutocomplete />
          </div>
          {isLoaded && (
            <GoogleMap
              zoom={10}
              center={center}
              mapContainerClassName="map-container"
            >
              {selected && <Marker position={selected} />}
            </GoogleMap>
          )}
        </div>
        {auth?.admin.adminType === "super" && (
          <div className="input-div">
            <label htmlFor="adminType">Choose Institution</label>
            <select
              name="adminType"
              id="adminType"
              value={institutionId}
              onChange={(e) => {
                setInstitutionId(e.target.value);
              }}
            >
              <option value="">--Choose Institution--</option>
              {institutions ? (
                institutions.map((val, key) => {
                  return (
                    <option key={key} value={val.uid}>
                      {val.name}
                    </option>
                  );
                })
              ) : (
                <option>Loading...</option>
              )}
            </select>
          </div>
        )}
        {/* <div className="input-div">
          <label htmlFor="item">Item(s) to be delivered</label>
          {items?.map((val, key) => {
            return (
              <div key={key} className="item-box">
                <input
                  type="text"
                  value={val.item}
                  id="item"
                  name="item"
                  onChange={(e) => handleItemChange(e, key)}
                ></input>
                {items.length > 1 && (
                  <button
                    className="remove-item"
                    onClick={(e, key) => {
                      handleRemoveItem(e, key);
                    }}
                  >
                    Remove Item-
                  </button>
                )}
                {items.length - 1 === key && (
                  <button className="add-item" onClick={handleAddItem}>
                    Add Item+
                  </button>
                )}
              </div>
            );
          })}
        </div> */}
        <div className="input-div">
          <button
            className="btn"
            onClick={async (e) => {
              await submitForm(e);
            }}
          >
            Update Parcel
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditParcel;
