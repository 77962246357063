import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import axios from "./api/axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const ADMIN_URL = "/administrators";
const INSTITUTION_URL = "/institutions";

function EditAdmin() {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [adminType, setAdminType] = useState("");
  const [isFilled, setIsFilled] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [error, setError] = useState("");
  const [isInstitution, setIsInstitution] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [institutions, setInstitutions] = useState();
  const [institutionID, setInstitutionID] = useState("");
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setFullname(location?.state.name);
    setEmail(location?.state.email);
    setAdminType(location?.state.adminType);
    setInstitutionID(location?.state.institutionId);
  }, []);

  const getInstitutions = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: auth?.userToken,
      },
    };

    try {
      const response = await axios.get(INSTITUTION_URL, config);
      setInstitutions(response.data);
    } catch (error) {
      setError("Could not get institution data. An error occured.");

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  };

  useEffect(() => {
    if (adminType === "institution") {
      setIsInstitution(true);
    }
    getInstitutions();
  }, [adminType]);

  const submitForm = async (e) => {
    e.preventDefault();

    if (
      fullname === location.state.name &&
      email === location.state.email &&
      adminType === location.state.adminType &&
      institutionID === location.state.institutionId
    ) {
      navigate("/listAdmin");
    } else {
      setLoading(true);
      setIsFilled(false);
      var updateData = {};

      if (fullname != location.state.name) {
        updateData = { name: fullname };
      }
      if (email != location.state.email) {
        updateData = { ...updateData, email: email };
      }
      if (adminType != location.state.adminType) {
        updateData = { ...updateData, adminType: adminType };
      }
      if (adminType != location.state.adminType && adminType === "delivery") {
        updateData = { ...updateData, institutionId: "" };
      }
      if (institutionID != location.state.institutionId) {
        updateData = { ...updateData, institutionId: institutionID };
      }

      //   var data = {
      //     data: {
      //       name: fullname,
      //       uid: "",
      //       email: email,
      //       adminType: adminType,
      //     },
      //     password: "123456",
      //   };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: auth?.userToken,
        },
      };

      var postData = JSON.stringify(updateData);
      console.log(postData);

      const route = ADMIN_URL + `/${location.state.uid}`

      try {
        const response = await axios.put(route, postData, config);

        if (response.status === 200) {
          setLoading(false);
          setFullname("");
          setEmail("");
          setAdminType("");
          setInstitutionID("");

          setIsAlertVisible(true);

          setTimeout(() => {
            setIsAlertVisible(false);
            navigate("/listAdmin");
          }, 3000);
        }
      } catch (error) {
        setLoading(false);
        setIsError(true);
        setError("Administrator could not be edited. An error occured.");

        setTimeout(() => {
          setIsError(false);
        }, 5000);
      }
    }
  };

  const handleAdminType = async (e) => {
    e.preventDefault();

    setAdminType(e.target.value);

    if (e.target.value === "institution") {
      setIsInstitution(true);
      getInstitutions();
    } else {
      setIsInstitution(false);
    }
  };

  return (
    <div className="add-dash">
      <form className="add-dash-details">
      <h2>Edit Sub Administrator</h2>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {isFilled && (
          <div className="error-container">
            <div className="error-inner">Fill all fields!</div>
          </div>
        )}
        {isAlertVisible && (
          <div className="alert-container">
            <div className="alert-inner">Administrator Updated!</div>
          </div>
        )}
        {isError && (
          <div className="error-container">
            <div className="error-inner">{error}</div>
          </div>
        )}
        <div className="input-div">
          <label htmlFor="fullname">Full Name</label>
          <input
            value={fullname}
            type="text"
            id="fullname"
            onChange={(e) => {
              setFullname(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label htmlFor="fullname">Email</label>
          <input
            value={email}
            type="text"
            id="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label htmlFor="adminType">Choose Admin Type</label>
          <select
            name="adminType"
            id="adminType"
            value={adminType}
            onChange={handleAdminType}
          >
            <option value="">--Choose Admin Type--</option>
            <option value="institution">Institution</option>
            <option value="delivery">Delivery</option>
          </select>
        </div>
        {isInstitution && (
          <div className="input-div">
            <label htmlFor="adminType">Choose your Institution</label>
            <select
              name="adminType"
              id="adminType"
              value={institutionID}
              onChange={(e) => {
                setInstitutionID(e.target.value);
              }}
            >
              <option value="">--Choose Institution--</option>
              {institutions ? (
                institutions.map((val, key) => {
                  return <option value={val.uid}>{val.name}</option>;
                })
              ) : (
                <span>Loading</span>
              )}
            </select>
          </div>
        )}
        <div className="input-div">
          <button
            className="btn"
            onClick={async (e) => {
              await submitForm(e);
            }}
          >
            Update Admin
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditAdmin;
