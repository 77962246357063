import "./App.css";
import AddDeliveryMan from "./Components/AddDeliveryMan";
import Layout from "./Components/Layout";
import { Route, Routes } from "react-router-dom";
import ListDeliveryMan from "./Components/ListDeliveryMan";
import AdminSignIn from "./Components/AdminSignIn";
import RequireAuth from "./Components/RequireAuth";
import AddAdmin from "./Components/AddAdmin";
import ListAdmin from "./Components/ListAdmin";
import EditAdmin from "./Components/EditAdmin";
import AddInstitution from "./Components/AddInstitution";
import ListInstitution from "./Components/ListInstitution";
import CreateParcel from "./Components/CreateParcel";
import ListParcels from "./Components/ListParcels";
import PickParcel from "./Components/PickParcel";
import AddDeliveryDetails from "./Components/AddDeliveryDetails";
import AssignDelivery from "./Components/AssignDelivery";
import TrackDelivery from "./Components/TrackDelivery";
import EditInstitution from "./Components/EditInstitution";
import Unauthorized from "./Components/Unauthorized";
import Welcome from "./Components/Welcome";
import EditDeliveryMan from "./Components/EditDeliveryman";
import EditParcel from "./Components/EditParcel";

function App() {
  return (
    <div className="main">
      <Routes>
        <Route path="adminSignIn" element={<AdminSignIn />} />

        <Route
          element={
            <RequireAuth allowedRoles={["super", "institution", "delivery"]} />
          }
        >
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Welcome />} />
            <Route element={<RequireAuth allowedRoles={["super"]} />}>
              <Route path="addAdmin" element={<AddAdmin />} />
              <Route path="listAdmin" element={<ListAdmin />} />
              <Route path="listAdmin/editAdmin" element={<EditAdmin />} />
            </Route>
            <Route
              element={<RequireAuth allowedRoles={["super", "institution"]} />}
            >
              
            </Route>
            <Route
              element={<RequireAuth allowedRoles={["super", "delivery"]} />}
            >
              <Route path="addDM" element={<AddDeliveryMan />} />
              <Route path="listDM" element={<ListDeliveryMan />} />
              <Route path="listDM/editDeliveryman" element={<EditDeliveryMan />} />
              <Route path="createDelivery" element={<PickParcel />} />
              <Route
                path="createDelivery/addDetails"
                element={<AddDeliveryDetails />}
              />
              <Route
                path="createDelivery/assignDelivery"
                element={<AssignDelivery />}
              />
              <Route path="addInstitution" element={<AddInstitution />} />
              <Route path="listInstitution" element={<ListInstitution />} />
              <Route
                path="listInstitution/editInstitution"
                element={<EditInstitution />}
              />
            </Route>
            <Route
              element={<RequireAuth allowedRoles={["super", "delivery", "institution"]} />}
            >
              <Route path="trackDelivery" element={<TrackDelivery />} />
              <Route path="addParcels" element={<CreateParcel />} />
              <Route path="listParcels" element={<ListParcels />} />
              <Route path="listParcels/editParcel" element={<EditParcel />} />
            </Route>
            <Route path="/unauthorized" element={<Unauthorized />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
