import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "./api/axios";
import useAuth from "./hooks/useAuth";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const LIST_PARCELS_URL = "/parcels";

function PickParcel() {
  const [parcels, setParcels] = useState();
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const { auth } = useAuth();
  const navigate = useNavigate();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: auth?.userToken,
    },
  };


  const addDetails = (val) => {
    navigate('addDetails', {state: val});
  }

  async function list() {
    try {
      const response = await axios.get(LIST_PARCELS_URL, config);
      setParcels(response.data);
    } catch (error) {
      setError(error.code);
      setLoading(false);

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }

  useEffect(() => {
    list();
  }, []);

  return (
    <div className="list-container">
      <h2>Choose Parcel to be Assigned</h2>
      {isError && (
        <div className="error-container">
          <div className="error-inner">{error}</div>
        </div>
      )}
      <table>
        <thead>
          <tr className="table-heading">
            <th>Receipient Name</th>
            <th>Receipient Number</th>
            <th>Receipient Location</th>
            <th>Institution</th>
            <th>Item</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {parcels ? (
            parcels.filter((item) => {
              return item?.status === "unassigned"
            }).map((val, key) => {
              return (
                <tr key={key} className="listed-item">
                  <td>
                    <span>{val.receipientInfo.fullname}</span>
                  </td>
                  <td>
                    <span>{val.receipientInfo.phoneNumber}</span>
                  </td>
                  <td>
                    <span>{val.toLocation.name}</span>
                  </td>
                  <td>
                    {val.institutionName}
                  </td>
                  <td>{val.itemName}</td>
                  <td>{val.status}</td>
                  <td>
                    {val.status === "unassigned" && <button className="list-button blue-button" onClick={() => addDetails(val)}>Create Delivery</button>}
                    {val.status !== "unassigned" && <button disabled className="list-button disabled-button" onClick={() => addDetails(val)}>Delivery Created</button>}
                  </td>
                </tr>
              );
            })
          ) : (
            // <Backdrop
            //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            //   open={loading}
            // >
            //   <CircularProgress color="inherit" />
            // </Backdrop>
            <CircularProgress color="inherit" />
          )}
        </tbody>
      </table>
    </div>
  );
}

export default PickParcel;
