import React, { useEffect, useState } from "react";
import axios from "./api/axios";
import useAuth from "./hooks/useAuth";
import BarChart from "./BarChart";
import { UserData } from "./Data";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
const LIST_ALL_DELIVERIES_URL = "/deliveries";
const STATISTICS_URL = "/statistics";

function Welcome() {
  const [deliveries, setDeliveries] = useState();
  const [earningStats, setEarningsStats] = useState();
  const [userData, setUserData] = useState({
    labels: [],
    datasets: []
  });
  const [deliveryStats, setDeliveryStats] = useState();
  const [generalStats, setGeneralStats] = useState();
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState();
  const { auth } = useAuth();
  const currentYear = new Date().getFullYear();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: auth?.userToken,
    },
  };

  async function setTable() {
    setUserData({
      labels: earningStats?.months.map((data) => getMonth(data.month)),
      datasets: [
        {
          label: "Earnings",
          data: earningStats?.months.map((data) => data.total_amount),
          borderRadius: 5,
        },
      ],
    });
  }

  async function getDeliveryStats() {
    const statsConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: auth?.userToken,
        "Cache-Control": "max-age=3600",
      },
    };
    try {
      const response = await axios.get(
        `${STATISTICS_URL}/deliveries`,
        statsConfig
      );
      setDeliveryStats(response.data);
      return response.data;
    } catch (error) {
      setError("Could not get delivery statistics. An error occured.");

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }

  const getMonth =(monthNO)=> {
    if (monthNO === 1) {
      return "January"
    } else if(monthNO === 2){
      return "February"
    } else if(monthNO === 3) {
      return "March"
    } else if(monthNO === 4) {
      return "April"
    } else if(monthNO === 5) {
      return "May"
    } else if (monthNO === 6) {
      return "June"
    } else if (monthNO === 7) {
      return "July"
    } else if (monthNO === 8) {
      return "August"
    } else if (monthNO === 9) {
      return "September"
    } else if (monthNO === 10) {
      return "October"
    } else if (monthNO === 11) {
      return "November"
    } else if (monthNO === 12) {
      return "December"
    }
  }

  async function getGeneralStats() {
    const statsConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: auth?.userToken,
        "Cache-Control": "max-age=3600",
      },
    };
    try {
      const response = await axios.get(
        `${STATISTICS_URL}/general`,
        statsConfig
      );
      setGeneralStats(response.data);
      return response.data;
    } catch (error) {
      setError("Could not get general statistics. An error occured.");

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }

  async function getEarnings() {
    const statsConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: auth?.userToken,
        "Cache-Control": "max-age=3600",
      },
    };
    try {
      const response = await axios.get(
        `${STATISTICS_URL}/${currentYear}`,
        statsConfig
      );
      setEarningsStats(response.data);
      return response.data;
    } catch (error) {
      setError("Could not get earnings statistics. An error occured.");

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }

  async function list() {
    try {
      const response = await axios.get(LIST_ALL_DELIVERIES_URL, config);
      if (response.status === 200) {
        setDeliveries(response.data);
      }
    } catch (error) {
      setError("Could not get delivery data. An error occured.");

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }

  useEffect(() => {
    list();
    if (
      auth?.admin.adminType === "super" ||
      auth?.admin.adminType === "delivery"
    ) {
      getDeliveryStats();
      getGeneralStats();
      getEarnings();
    }
    // const intervalId = setInterval(list, 10000);

    // return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setTable();
  }, [earningStats])

  return (
    <div className="add-dash">
      <div className="dashboard">
        {isError && (
          <div className="error-container">
            <div className="error-inner">{error}</div>
          </div>
        )}
        <h2>Welcome, {auth?.admin?.name}</h2>
        <div className="info-dash">
          {auth?.admin.adminType === "super" && (
            <div className="top">
              <div className="earnings">
                <h3>Your Earnings this month</h3>
                <div className="value">
                  <p>GHc</p>
                  <span>{earningStats?.total_amount}</span>
                </div>
                <div></div>
              </div>
              <div className="barchart" style={{ width: 500 }}>
                <BarChart chartData={userData} />
              </div>
            </div>
          )}
          {(auth?.admin.adminType === "super" ||
            auth?.admin.adminType === "delivery") && (
            <div className="delivery-info-cards">
              <h3>Delivery Info</h3>
              <div className="deliveries">
                <div className="info-card">
                  <div>
                    <h4>Pending Deliveries</h4>
                    <p>{deliveryStats?.totalNoPendingDeliveris}</p>
                  </div>
                  <Link to="/trackDelivery" state={{ state: "pending" }}>
                    See All
                  </Link>
                </div>
                <div className="info-card">
                  <div>
                    <h4>Cancelled Deliveries</h4>
                    <p>{deliveryStats?.totalNoInCancelledDeliveris}</p>
                  </div>
                  <Link to="/trackDelivery" state={{ state: "open" }}>
                    See All
                  </Link>
                </div>
                <div className="info-card">
                  <div>
                    <h4>In Progress Deliveries</h4>
                    <p>{deliveryStats?.totalNoInProgressDeliveris}</p>
                  </div>
                  <Link to="/trackDelivery" state={{ state: "inprogress" }}>
                    See All
                  </Link>
                </div>
                <div className="info-card">
                  <div>
                    <h4>Completed Deliveries</h4>
                    <p>{deliveryStats?.totalNoCompletedDeliveris}</p>
                  </div>
                  <Link to="/trackDelivery" state={{ state: "completed" }}>
                    See All
                  </Link>
                </div>
              </div>
            </div>
          )}
          {(auth?.admin.adminType === "super" ||
            auth?.admin.adminType === "delivery") && (
            <div className="delivery-info-cards">
              <h3>General Info</h3>
              <div className="deliveries">
                <div className="info-card" style={{ height: 70 }}>
                  <div>
                    <h4>Total Deliveries</h4>
                    <p>{generalStats?.totalNoOfDeliveries}</p>
                  </div>
                </div>
                <div className="info-card" style={{ height: 70 }}>
                  <div>
                    <h4>Riders</h4>
                    <p>{generalStats?.totalNoRiders}</p>
                  </div>
                </div>
                <div className="info-card" style={{ height: 70 }}>
                  <div>
                    <h4>Institutions</h4>
                    <p>{generalStats?.totalNoInstituions}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <div className='earnings-container'>
          <div className='earnings'>
            <h3>Your Earnings</h3>
            <p>GHc</p>
            <span>500.00</span>
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
}

export default Welcome;
