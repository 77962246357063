import { useRef } from "react";
import { FaBars, FaTimes, FaCaretDown, FaHome, FaBoxes } from "react-icons/fa";
import { FaPersonBiking } from "react-icons/fa6";
import { RiAdminFill, RiUserAddFill } from "react-icons/ri";
import { MdDeliveryDining } from "react-icons/md";
import { BsFillBuildingFill, BsBuildingFillAdd, BsCardList } from "react-icons/bs";
import { FiPackage } from "react-icons/fi";
import { LuPackagePlus } from "react-icons/lu";
import { PiUserListFill } from "react-icons/pi";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth as FirebaseAuth } from "./firebase";
import useAuth from "./hooks/useAuth";

function Navbar() {
  const navigate = useNavigate();
  const navRef = useRef();
  const sideRef = useRef();
  const subRef1 = useRef();
  const arrowRef1 = useRef();
  const subRef2 = useRef();
  const arrowRef2 = useRef();
  const subRef3 = useRef();
  const arrowRef3 = useRef();
  const subRef4 = useRef();
  const arrowRef4 = useRef();
  const subRef5 = useRef();
  const arrowRef5 = useRef();
  const { auth } = useAuth();
  const active = 'activeLink';
  const normal = '';

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  const showSidenav = () => {
    sideRef.current.classList.toggle("responsive_side");
  };
  const showSub1 = (e) => {
    e.preventDefault();
    subRef1.current.classList.toggle("active");
    arrowRef1.current.classList.toggle("active");
  }
  const showSub2 = (e) => {
    e.preventDefault();
    subRef2.current.classList.toggle("active");
    arrowRef2.current.classList.toggle("active");
  }
  const showSub3 = (e) => {
    e.preventDefault();
    subRef3.current.classList.toggle("active");
    arrowRef3.current.classList.toggle("active");
  }
  const showSub4 = (e) => {
    e.preventDefault();
    subRef4.current.classList.toggle("active");
    arrowRef4.current.classList.toggle("active");
  }
  const showSub5 = (e) => {
    e.preventDefault();
    subRef5.current.classList.toggle("active");
    arrowRef5.current.classList.toggle("active");
  }

  const handleLogout = () => {
    signOut(FirebaseAuth).then(() => {
      navigate("/adminSignIn");
    }).catch((error) => {
      navigate("/");
    })
  }

  return (
    <div className="navigation">
      <header>
        <div className="nav-div">
          <button className="side-btn" onClick={showSidenav}>
            <FaBars />
          </button>
          <div className="nav-logo">
            <img src={require("../images/tups logo.jpg")} alt=""></img>
          </div>
          <Link className="home" to="/">Delivery Management</Link>
        </div>
        <div className="nav-div">
          <nav className="signOut-nav" ref={navRef}>
            <button onClick={handleLogout} className="signOut-button">Sign Out</button>
            <button className="nav-btn nav-close-btn" onClick={showNavbar}>
              <FaTimes />
            </button>
          </nav>
          <button className="nav-btn" onClick={showNavbar}>
            <FaBars />
          </button>
        </div>
      </header>

      <div ref={sideRef} className="sidenav">
        <nav>
          <div className="mainLinks">
            <NavLink className={({ isActive }) => isActive ? active : normal} to="/" onClick={showSidenav}><FaHome/>Dashboard</NavLink>
            <NavLink className={({ isActive }) => isActive ? active : normal} to="/trackDelivery" onClick={showSidenav}><FaBoxes />Deliveries</NavLink>
            {(auth?.admin.adminType === "super" || auth?.admin.adminType === "delivery") && <NavLink className={({ isActive }) => isActive ? active : normal} to="/createDelivery" onClick={showSidenav}><MdDeliveryDining />Assign Deliveries</NavLink>}
          </div>
          {(auth?.admin.adminType === "super" || auth?.admin.adminType === "delivery") && <div className="subMenu">
            <div ref={arrowRef1} className="subMenu-heading">
              <a href="/#" onClick={(e) => showSub1(e)}><p><FaPersonBiking />Rider Management</p> <FaCaretDown className="dropdown-arrow" /></a>
            </div>
            <div ref={subRef1} className="subMenuList">
              <NavLink className={({ isActive }) => isActive ? active : normal} to="/addDM" onClick={showSidenav}><RiUserAddFill />Add Rider</NavLink>
              <NavLink className={({ isActive }) => isActive ? active : normal} to="/listDM" onClick={showSidenav}><PiUserListFill />Rider List</NavLink>
            </div>
          </div>}
          {auth?.admin.adminType === "super" && <div className="subMenu">
            <div ref={arrowRef2} className="subMenu-heading">
              <a href="/#" onClick={(e) => showSub2(e)}><p><RiAdminFill />Admin Management</p> <FaCaretDown className="dropdown-arrow" /></a>
            </div>
            <div ref={subRef2} className="subMenuList">
              <NavLink className={({ isActive }) => isActive ? active : normal} to="/addAdmin" onClick={showSidenav}><RiUserAddFill />Add Sub Admin</NavLink>
              <NavLink className={({ isActive }) => isActive ? active : normal} to="/listAdmin" onClick={showSidenav}><PiUserListFill />List Admins</NavLink>
            </div>
          </div>}
          {(auth?.admin.adminType === "super" || auth?.admin.adminType === "delivery") && <div className="subMenu">
            <div ref={arrowRef3} className="subMenu-heading">
              <a href="/#" onClick={(e) => showSub3(e)}><p><BsFillBuildingFill />Institutions</p> <FaCaretDown className="dropdown-arrow" /></a>
            </div>
            <div ref={subRef3} className="subMenuList">
              <NavLink className={({ isActive }) => isActive ? active : normal} to="/addInstitution" onClick={showSidenav}><BsBuildingFillAdd />Add Institution</NavLink>
              <NavLink className={({ isActive }) => isActive ? active : normal} to="/listInstitution" onClick={showSidenav}><BsCardList />List Institution</NavLink>
            </div>
          </div>}
          {(auth?.admin.adminType === "super" || auth?.admin.adminType === "institution" || auth?.admin.adminType === "delivery") && <div className="subMenu">
            <div ref={arrowRef4} className="subMenu-heading">
              <a href="/#" onClick={(e) => showSub4(e)}><p><FiPackage />Parcels</p> <FaCaretDown className="dropdown-arrow" /></a>
            </div>
            <div ref={subRef4} className="subMenuList">
              <NavLink className={({ isActive }) => isActive ? active : normal} to="/addParcels" onClick={showSidenav}><LuPackagePlus />Add Parcels</NavLink>
              <NavLink className={({ isActive }) => isActive ? active : normal} to="/listParcels" onClick={showSidenav}><BsCardList />List Parcels</NavLink>
            </div>
          </div>}
          {/* {(auth?.admin.adminType === "super" || auth?.admin.adminType === "delivery") && <div className="subMenu">
            <div ref={arrowRef5} className="subMenu-heading">
              <a href="/#" onClick={(e) => showSub5(e)}>Delivery <FaCaretDown className="dropdown-arrow" /></a>
            </div>
            <div ref={subRef5} className="subMenuList">
              <NavLink className={({ isActive }) => isActive ? active : normal} to="/createDelivery" onClick={showSidenav}>Create</NavLink>
              <NavLink className={({ isActive }) => isActive ? active : normal} to="/trackDelivery" onClick={showSidenav}>Track</NavLink>
            </div>
          </div>} */}
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
