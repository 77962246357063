import React from 'react'

function Unauthorized() {
  return (
    <div className='add-dash'>
        <h2>You are not authorized to view this page</h2>
    </div>
  )
}

export default Unauthorized