import React, { useState } from "react";
import useAuth from "./hooks/useAuth";
import axios from "./api/axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const ADMIN_URL = "/administrators";
const INSTITUTION_URL = "/institutions";

function AddAdmin() {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [adminType, setAdminType] = useState("");
  const [isFilled, setIsFilled] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isInstitution, setIsInstitution] = useState(false);
  const [institutions, setInstitutions] = useState();
  const [institution, setInstitution] = useState({ institutionId: "", institutionName: ""});
  const [password, setPassword] = useState("");
  const { auth } = useAuth();

  const submitForm = async (e) => {
    e.preventDefault();

    if (fullname === "" || email === "" || adminType === "" || password === "") {
      setIsFilled(true);

      setTimeout(() => {
        setIsFilled(false);
      }, 5000);
    } else {
      setLoading(true);
      setIsFilled(false);

      var data = {
        data: {
          name: fullname,
          uid: "",
          email: email,
          adminType: adminType,
          institutionId: institution.institutionId,
          institutionName: institution.institutionName
        },
        password: password,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: auth?.userToken,
        },
      };

      var postData = JSON.stringify(data);

      try {
        const response = await axios.post(ADMIN_URL, postData, config);

        if (response.status === 200) {
          setLoading(false);
          setFullname("");
          setEmail("");
          setAdminType("");
          setInstitution({ institutionId: "", institutionName: ""});
          setPassword("");

          setIsAlertVisible(true);

          setTimeout(() => {
            setIsAlertVisible(false);
          }, 3000);
        }
      } catch (error) {
        setIsError(true);
        setError("Could not create sub admin. An error occured.");

        setTimeout(() => {
          setIsError(false);
        }, 5000);
      }
    }
  };

  const handleAdminType = async (e) => {
    e.preventDefault();

    setAdminType(e.target.value);

    if (e.target.value === "institution") {
      setIsInstitution(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: auth?.userToken,
        },
      };

      try {
        const response = await axios.get(INSTITUTION_URL, config);
        setInstitutions(response.data);
      } catch (error) {
        setError("Could not load institution data. An error occured.");

        setIsError(true);

        setTimeout(() => {
          setIsError(false);
        }, 5000);
      }
    } else {
      setIsInstitution(false);
    }
  };

  return (
    <div className="add-dash">
      <form className="add-dash-details">
      <h2>Add Sub Administrator</h2>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {isFilled && (
          <div className="error-container">
            <div className="error-inner">Fill all fields!</div>
          </div>
        )}
        {isAlertVisible && (
          <div className="alert-container">
            <div className="alert-inner">Administrator created!</div>
          </div>
        )}
        {isError && (
          <div className="error-container">
            <div className="error-inner">{error}</div>
          </div>
        )}
        <div className="input-div">
          <label htmlFor="fullname">Full Name</label>
          <input
            value={fullname}
            type="text"
            id="fullname"
            onChange={(e) => {
              setFullname(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label htmlFor="fullname">Email</label>
          <input
            value={email}
            type="text"
            id="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label htmlFor="adminType">Choose Admin Type</label>
          <select
            name="adminType"
            id="adminType"
            value={adminType}
            onChange={handleAdminType}
          >
            <option value="">--Choose Admin Type--</option>
            <option value="institution">Institution</option>
            <option value="delivery">Delivery</option>
          </select>
        </div>
        {isInstitution && (
          <div className="input-div">
            <label htmlFor="adminType">Choose your Institution</label>
            <select
              name="adminType"
              id="adminType"
              value={institution.institutionID}
              onChange={(e) => {
                const data = JSON.parse(e.target.selectedOptions[0].dataset.info);
                setInstitution({ institutionId: data.id, institutionName: data.name });

              }}
            >
              <option value="">--Choose Institution--</option>
              {institutions? institutions.map((val, key) => {
                return <option key={key} value={val.uid} data-info={JSON.stringify({"name": val.name, "id": val.uid})}>{val.name}</option>;
              }): <span>Loading</span>}
            </select>
          </div>
        )}
        <div className="input-div">
          <label htmlFor="fullname">Password</label>
          <input
            value={password}
            type="text"
            id="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <button
            className="btn"
            onClick={async (e) => {
              await submitForm(e);
            }}
          >
            Add Admin
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddAdmin;
