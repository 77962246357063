import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "./hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();

    return(
        allowedRoles?.some(role => role === auth?.admin?.adminType)
            ?<Outlet />
            :auth?.userToken
                ?<Navigate to="/unauthorized" state={{from: location}} replace />
                :<Navigate to="/adminSignIn" state={{from: location}} replace />
    );
}

export default RequireAuth;