import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "./api/axios";
import useAuth from "./hooks/useAuth";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const RIDER_URL = "/riders";
const DELIVERY_URL = "/deliveries"

function AssignDelivery() {
  const [deliverymen, setDeliveryMen] = useState();
  const [isError, setIsError] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { auth } = useAuth();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: auth?.userToken,
    },
  };

  const list = async () => {
    try {
      const response = await axios.get(RIDER_URL, config);
      setDeliveryMen(response.data);
    } catch (error) {}
  };

  const assignDelivery = async (delivermanID, deliverymanName) => {
    setLoading(true);
    const route = DELIVERY_URL + `/${location?.state.id}/assign/${delivermanID}`
    try {
      const assignConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: auth?.userToken,
        },
        options: {
          withCredentials: true,
          credentials: "include",
        },
        params: {
          riderName: deliverymanName
        },
      };

      const response = await axios.put(route,{},assignConfig);

      if (response.status === 200) {
        setLoading(false);

        setIsAlertVisible(true);

        setTimeout(() => {
          setIsAlertVisible(false);
          navigate("/trackDelivery");
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      setIsError(true);
      setError("Could not assign deliveryman. An error occured");

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  };

  useEffect(() => {
    list();
  }, []);

  return (
    <div className="list-container">
      <h2>Deliveryman List</h2>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {isError && (
        <div className="error-container">
          <div className="error-inner">{error}</div>
        </div>
      )}
      {isAlertVisible && (
        <div className="alert-container">
          <div className="alert-inner">Delivery assigned!</div>
        </div>
      )}
      <table>
        <thead>
          <tr className="table-heading">
            <th>Name</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {deliverymen ? (
            deliverymen.map((val, key) => {
              return (
                <tr className="listed-item">
                  <td className="profile-name">
                    <div className="profile-picture">
                      <img src={val.passportPhoto} alt=""></img>
                    </div>
                    <span>{val.fullname}</span>
                  </td>
                  <td>
                    <span>{val.phoneNumber}</span>
                  </td>
                  <td>
                    <span>{val.email}</span>
                  </td>
                  <td>
                    <button className="list-button green-button" onClick={() => assignDelivery(val.uid, val.fullname)}>
                      Assign Delivery
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default AssignDelivery;
