import React, { useEffect, useState } from "react";
import axios from "./api/axios";
import useAuth from "./hooks/useAuth";
import Backdrop from "@mui/material/Backdrop";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Timestamp } from "firebase/firestore";
const DELIVERY_URL = "/deliveries";


function TrackDelivery() {
  const [deliveries, setDeliveries] = useState();
  const [status, setStatus] = useState("");
  const [isError, setIsError] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const location = useLocation();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: auth?.userToken,
    },
  };

  const filterDeliveries = (instId) => {
    return deliveries
      ? deliveries.filter((e) => e.parcel.institutionId === instId)
      .map((val, key) => (
        <tr className="listed-items">
          <td>{val.parcel.receipientInfo.fullname}</td>
          <td>{val.parcel.toLocation.name}</td>
          <td>{val.parcel.institutionName}</td>
          <td>{val.status}</td>
          <td>{val.riderName}</td>
        </tr>
      )): <CircularProgress color="inherit" />;
  };

  const getDate = (date) => {
    let timestamp = new Timestamp(date?._seconds, date?._nanoseconds);
    let fdate = timestamp.toDate().toDateString();

    return <span>{fdate}</span>;
  }

  async function list() {
    try {
      const response = await axios.get(DELIVERY_URL, config);

      if (response.status === 200) {
        setDeliveries(response.data);
      }
    } catch (error) {
      setLoading(false);
      setError("Could not get delivery data. An error occured.");

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }

  async function listByStatus() {
    const filterConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: auth?.userToken,
      },
      params: {
        status: status,
      },
    };

    const route = DELIVERY_URL + `/${status}`;

    try {
      const response = await axios.get(route, filterConfig);
      await setDeliveries(response.data);
      setLoading(false);
    } catch (error) {
      setError(error.code);
      setLoading(false);

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }

  async function listByInstitutions() {
    const route = DELIVERY_URL + `/${auth?.admin.institutionId}`;
    console.log(route);

    try {
      const response = await axios.get(route, config);

      if (response.status === 200) {
        setDeliveries(response.data);
      }
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
      console.log(error.response.data.message);

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }

  async function handleSelectStatus(e) {
    setLoading(true);
    setStatus(e.target.value);

    listByStatus();
  }

  // useEffect(() => {
  //   if (location?.state) {
  //     setStatus(location.state)
  //   }
  // },[])

  useEffect(() => {
    if (status !== "") {
      listByStatus();
      // const listedItems = filterDeliveries(auth?.admin.institutionId);
      // setDeliveries(listedItems);
      const intervalId = setInterval(listByStatus, 10000);

      return () => clearInterval(intervalId);
    } else {
      list();
      // const listedItems = filterDeliveries(auth?.admin.institutionId);
      // setDeliveries(listedItems);
      const intervalId = setInterval(list, 10000);

      return () => clearInterval(intervalId);
    }
  }, [status]);

  return (
    <div className="list-container">
      <h2>Track Deliveries</h2>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {loading && <CircularProgress color="inherit" />}
      {isError && (
        <div className="error-container">
          <div className="error-inner">{error}</div>
        </div>
      )}
      {isAlertVisible && (
        <div className="alert-container">
          <div className="alert-inner">Institution deleted!</div>
        </div>
      )}
      <div className="input-div">
        <label htmlFor="status">Sort By:</label>
        <select
          name="status"
          id="status"
          value={status}
          onChange={handleSelectStatus}
        >
          <option value="">--Choose Status--</option>
          <option value="open">Open</option>
          <option value="pending">Pending</option>
          <option value="inprogress">In Progress</option>
          <option value="payment-failed">Payment Failed</option>
          <option value="completed">Completed</option>
          <option value="cancelled">Cancelled</option>
        </select>
      </div>
      <table>
        <thead>
          <tr className="table-heading">
            <th>Receipient Name</th>
            <th>Receipient Location</th>
            <th>Institution</th>
            <th>Status</th>
            <th>Assigned Deliveryman</th>
            <th>Created on</th>
          </tr>
        </thead>
        <tbody>
          {auth?.admin.adminType !== "institution" && deliveries ? (
            deliveries.map((val, key) => {
              return (
                <tr className="listed-items" key={key}>
                  <td>{val.parcel.receipientInfo.fullname}</td>
                  <td>{val.parcel.toLocation.name}</td>
                  <td>{val.parcel.institutionName}</td>
                  <td>{val.status}</td>
                  <td>{val.riderName}</td>
                  <td>{getDate(val.createdDate)}</td>
                </tr>
              );
            })
          ) : (
            auth?.admin.adminType !== "institution" && <CircularProgress color="inherit" />
          )}
          {auth?.admin.adminType === "institution" &&
            filterDeliveries(auth?.admin.institutionId)}
        </tbody>
      </table>
    </div>
  );
}

export default TrackDelivery;
