import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "./api/axios";
import useAuth from "./hooks/useAuth";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const ADMIN_URL = "/administrators";

function ListAdmin() {
  const [admin, setAdmins] = useState();
  const [isError, setIsError] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const navigate = useNavigate();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: auth?.userToken,
    },
  };

  const editAdmin = (val) => {
    navigate("editAdmin", { state: val });
  };

  const deleteAdmin = async (uid) => {
    setLoading(true);
    try {
      const delConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: auth?.userToken,
        },
      };

      const route = ADMIN_URL + `/${uid}`;

      await axios
        .delete(route, delConfig)
        .then(async (res) => {
          await list().then(async (res) => {
            setIsAlertVisible(true);

            setTimeout(() => {
              setIsAlertVisible(false);
            }, 3000);
            setLoading(false);
          });
        })
        .catch((error) => {
          setError("Could not delete administrator. An error occured.");
          setLoading(false);

          setIsError(true);

          setTimeout(() => {
            setIsError(false);
          }, 5000);
        });
    } catch (error) {
      setError(error.code);

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  };

  async function list() {
    try {
      const response = await axios.get(ADMIN_URL, config);
      setAdmins(response.data);
    } catch (error) {
      setError(error.code);
      setLoading(false);

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }

  useEffect(() => {
    list();
  }, []);

  return (
    <div className="list-container">
      <h2>Admin List</h2>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {loading && <CircularProgress color="inherit" />}
      {isError && (
        <div className="error-container">
          <div className="error-inner">{error}</div>
        </div>
      )}
      {isAlertVisible && (
        <div className="alert-container">
          <div className="alert-inner">Administrator deleted!</div>
        </div>
      )}
      <table>
        <thead>
          <tr className="table-heading">
            <th>Name</th>
            <th>Email</th>
            <th>Admin Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {admin ? (
            admin.map((val, key) => {
              return (
                <tr className="listed-item">
                  <td>
                    <span>{val.name}</span>
                  </td>
                  <td>
                    <span>{val.email}</span>
                  </td>
                  <td>
                    <span>{val.adminType}</span>
                  </td>
                  <td>
                    {val.adminType != "super" && (
                      <button
                        className="list-button blue-button"
                        onClick={() => editAdmin(val)}
                      >
                        Edit
                      </button>
                    )}
                    {val.adminType != "super" && (
                      <button
                        className="list-button red-button"
                        onClick={() => deleteAdmin(val.uid)}
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            // <Backdrop
            //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            //   open={true}
            // >
            //   <CircularProgress color="inherit" />
            // </Backdrop>
            <CircularProgress color="inherit" />
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ListAdmin;
