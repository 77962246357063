import React, { useState } from "react";
import useAuth from "./hooks/useAuth";
import axios from "./api/axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const INSTITUTION_URL = "/institutions";

function AddInstitution() {
  const [name, setName] = useState("");
  const [region, setRegion] = useState("");
  const [branch, setBranch] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isFilled, setIsFilled] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();

  const submitForm = async (e) => {
    e.preventDefault();

    if (name === "" || region === "" || branch === ""|| phoneNumber === "") {
      setIsFilled(true);

      setTimeout(() => {
        setIsFilled(false);
      }, 5000);
    } else {
      setLoading(true);
      setIsFilled(false);

      var data = {
        name: name,
        uid: "",
        region: region,
        branch: branch,
        phoneNumber: phoneNumber,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: auth?.userToken,
        },
      };

      var postData = JSON.stringify(data);
      console.log(postData);

      try {
        const response = await axios.post(INSTITUTION_URL, postData, config);

        if (response.status === 200) {
          setLoading(false);
          setName("");
          setRegion("");
          setBranch("");
          setPhoneNumber("");

          setIsAlertVisible(true);

          setTimeout(() => {
            setIsAlertVisible(false);
          }, 3000);
        }
      } catch (error) {
        setLoading(false);
        setIsError(true);
        setError("Could not add institution. An error occured.");

        setTimeout(() => {
          setIsError(false);
        }, 5000);
      }
    }
  };

  return (
    <div className="add-dash">
      <form className="add-dash-details">
      <h2>Add Institution</h2>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {isFilled && (
          <div className="error-container">
            <div className="error-inner">Fill all fields!</div>
          </div>
        )}
        {isAlertVisible && (
          <div className="alert-container">
            <div className="alert-inner">Institution created!</div>
          </div>
        )}
        {isError && (
          <div className="error-container">
            <div className="error-inner">{error}</div>
          </div>
        )}
        <div className="input-div">
          <label htmlFor="fullname">Name of Institution</label>
          <input
            value={name}
            type="text"
            id="fullname"
            onChange={(e) => {
              setName(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label htmlFor="fullname">Region</label>
          <input
            value={region}
            type="text"
            id="email"
            onChange={(e) => {
              setRegion(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label htmlFor="fullname">Branch</label>
          <input
            value={branch}
            type="text"
            id="email"
            onChange={(e) => {
              setBranch(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label htmlFor="fullname">Phone Number</label>
          <input
            value={phoneNumber}
            type="text"
            id="email"
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          ></input>
        </div>
        <div className="input-div">
          <button
            className="btn"
            onClick={async (e) => {
              await submitForm(e);
            }}
          >
            Add Institution
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddInstitution;
