import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import axios from "./api/axios";
import useAuth from "./hooks/useAuth";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const RIDER_URL = "/riders";

function ListDeliveryMan() {
  const [deliverymen, setDeliveryMen] = useState();
  const [isError, setIsError] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isToken, setIsToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();
  const { auth } = useAuth();

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: auth?.userToken,
    },
  };

  const editDeliveryman = (val) => {
    navigate("editDeliveryman", { state: val });
  };

  const deleteDeliveryMan = async (uid) => {
    setLoading(true);
    try {
      const delConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: auth?.userToken,
        },
        // params: {
        //   uid: uid,
        // },
      };
      const route = RIDER_URL + `/${uid}`;

      await axios
        .delete(route, delConfig)
        .then(async (res) => {
          await list().then(async (res) => {
            setLoading(false);
            setIsAlertVisible(true);

            setTimeout(() => {
              setIsAlertVisible(false);
            }, 3000);
          });
        })
        .catch((error) => {
          setLoading(false);
          setError("Could not delete rider. An error occured.");

          setIsError(true);

          setTimeout(() => {
            setIsError(false);
          }, 5000);
        });
    } catch (error) {
      setError(error.code);

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  };

  const list = async () => {
    try {
      const response = await axios.get(RIDER_URL, config);
      setDeliveryMen(response.data);
    } catch (error) {
      setError(error.code);
      setLoading(false);

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  };

  const generateToken = async (uid) => {
    setLoading(true);
    const genConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: auth?.userToken,
      },
      // params: {
      //   delivery_id: uid,
      // },
    };

    const route = RIDER_URL + `/${uid}/generate-token`;

    try {
      await axios.put(route, {}, genConfig);
      setLoading(false);
      setIsToken(true);

      setTimeout(() => {
        setIsToken(false);
      }, 5000);
    } catch (error) {
      setLoading(false);
      setError("Token could not be generated. An error occured.");

      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  };

  useEffect(() => {
    list();
  }, []);

  return (
    <div className="list-container">
      <h2>Rider List</h2>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {loading && <CircularProgress color="inherit" />}
      {isError && (
        <div className="error-container">
          <div className="error-inner">{error}</div>
        </div>
      )}
      {isAlertVisible && (
        <div className="alert-container">
          <div className="alert-inner">Delivery man deleted!</div>
        </div>
      )}
      {isToken && (
        <div className="alert-container">
          <div className="alert-inner">Token Generated</div>
        </div>
      )}
      <table>
        <thead>
          <tr className="table-heading">
            <th>Name</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {deliverymen ? (
            deliverymen.map((val, key) => {
              return (
                <tr className="listed-item">
                  <td className="profile-name">
                    <div className="profile-picture">
                      <img src={val.passportPhoto} alt=""></img>
                    </div>
                    <span>{val.fullname}</span>
                  </td>
                  <td>
                    <span>{val.phoneNumber}</span>
                  </td>
                  <td>
                    <span>{val.email}</span>
                  </td>
                  <td>
                    <button
                      className="list-button blue-button"
                      onClick={() => editDeliveryman(val)}
                    >
                      Edit
                    </button>
                    <button
                      className="list-button red-button"
                      onClick={() => deleteDeliveryMan(val.uid)}
                    >
                      Delete
                    </button>
                    <button
                      className="list-button green-button"
                      onClick={() => generateToken(val.uid)}
                    >
                      Generate Token
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            // <Backdrop
            //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            //   open={true}
            // >
            //   <CircularProgress color="inherit" />
            // </Backdrop>
            <CircularProgress color="inherit" />
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ListDeliveryMan;
