import React, { useState, useEffect } from "react";
import axios from "./api/axios";
import { useRef } from "react";
import { storage } from "./firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import useAuth from "./hooks/useAuth";

const RIDER_URL = "/riders";

function AddDeliveryMan() {
  const [fullname, setFullName] = useState("");
  const [passportPhoto, setPassportPhoto] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [email, setEmail] = useState("");
  const [licenseNo, setLicenseNo] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const photoRef = useRef(null);
  const { auth } = useAuth();

  const uploadImage = async () => {
    if (passportPhoto == null) {
      setIsFilled(true);

      setTimeout(() => {
        setIsFilled(false);
      }, 5000);
    } else {
      setLoading(true);
      const imageName = passportPhoto.name + v4();

      const imageRef = ref(storage, `passport_photos/${imageName}`);
      const uploadTask = uploadBytesResumable(imageRef, passportPhoto);

      await uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is" + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              console.log("...");
          }
        },
        (error) => {
          throw error;
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setImageURL(url);
          });
        }
      );
    }
  };

  const save = async (e) => {
    if (
      fullname === "" ||
      email === "" ||
      licenseNo === "" ||
      phoneNumber === "" ||
      address === "" ||
      passportPhoto === null
    ) {
      setIsFilled(true);

      setTimeout(() => {
        setIsFilled(false);
      }, 5000);
    } else if (imageURL === "") {
      setIsImage(true);

      setTimeout(() => {
        setIsImage(false);
      }, 5000);
    } else {
      setIsFilled(false);

      var data = {
        fullname: fullname,
        email: email,
        passportPhoto: imageURL,
        licenseNo: licenseNo,
        phoneNumber: phoneNumber,
        address: address,
        region: "",
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: auth?.userToken,
        },
      };

      var postData = JSON.stringify(data);
      await axios
        .post(RIDER_URL, postData, config)
        .then((res) => {
          if (res.status === 200) {
            setIsAlertVisible(true);
            setLoading(false);

            setTimeout(() => {
              setIsAlertVisible(false);
            }, 3000);

            setAddress("");
            setEmail("");
            setFullName("");
            setIsError(false);
            setIsFilled(false);
            setPhoneNumber("");
            setLicenseNo("");
            setPassportPhoto(null);
            photoRef.current.value = null;
          } else {
            setLoading(false);
            setIsError(true);
            setError("Could not upload file. Please try again.");

            setTimeout(() => {
              setIsError(false);
            }, 3000);
          }
        })
        .catch((error) => {
          setError("Could not add rider. An error occured.");

          setIsError(true);
          setTimeout(() => {
            setIsError(false);
          }, 3000);
        });
    }
  };

  useEffect(() => {
    save();
  }, [imageURL]);

  const submitForm = async (e) => {
    e.preventDefault();

    await uploadImage();
  };

  return (
    <div className="add-dash">
      <form className="add-dash-details">
        <h2>Add Delivery Man</h2>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="input-div">
          <label htmlFor="pass_img">Passport Photo</label>
          <input
            ref={photoRef}
            type="file"
            id="pass_img"
            accept="image"
            onChange={(e) => {
              setPassportPhoto(e.target.files[0]);
            }}
            required
          ></input>
        </div>

        <div className="input-div">
          <label htmlFor="fullname">Full Name </label>
          <input
            value={fullname}
            type="text"
            id="fullname"
            onChange={(e) => {
              setFullName(e.target.value);
            }}
          ></input>
        </div>

        <div className="input-div">
          <label htmlFor="email">Email </label>
          <input
            value={email}
            type="text"
            id="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></input>
        </div>

        <div className="input-div">
          <label htmlFor="pass_img">License Plate Number </label>
          <input
            value={licenseNo}
            type="text"
            id="licenseNumber"
            onChange={(e) => {
              setLicenseNo(e.target.value);
            }}
          ></input>
        </div>

        <div className="input-div">
          <label htmlFor="pass_img">Mobile Number </label>
          <input
            value={phoneNumber}
            type="text"
            id="mobile"
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          ></input>
        </div>

        <div className="input-div">
          <label htmlFor="pass_img">Address </label>
          <input
            value={address}
            type="text"
            id="address"
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          ></input>
        </div>

        {isAlertVisible && (
          <div className="alert-container">
            <div className="alert-inner">Delivery Man created!</div>
          </div>
        )}
        {isFilled && (
          <div className="error-container">
            <div className="error-inner">Fill all fields!</div>
          </div>
        )}
        {isImage && (
          <div className="error-container">
            <div className="error-inner">Error Uploading Image!</div>
          </div>
        )}
        {isError && (
          <div className="error-container">
            <div className="error-inner">{error}</div>
          </div>
        )}

        <div className="input-div">
          <button
            className="btn"
            onClick={async (e) => {
              await submitForm(e);
            }}
          >
            Create
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddDeliveryMan;
