// Import the functions you need from the SDKs you need
import { initializeApp, firebase } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage} from "firebase/storage";
import { getFirestore } from 'firebase/firestore'
import 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCZ5Diy4KokLwEuosQqbIB3b-m3RGKaZL8",
  authDomain: "the-uncles-parcel-service.firebaseapp.com",
  projectId: "the-uncles-parcel-service",
  storageBucket: "the-uncles-parcel-service.appspot.com",
  messagingSenderId: "657297346821",
  appId: "1:657297346821:web:e6a96f1d2e14ea82dbe76c",
  measurementId: "G-M5GE896ZSL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export default app;